export const committeeList = [
  {
    name: "Аганбегян Абел Гезевич",
    descr: "академик РАН, доктор экономических наук",
    location: "Россия, Москва",
    img: "aganbegyan.jpg",
  },
  {
    name: "Бахтизин Альберт Рауфович",
    descr: "член-корреспондент РАН, профессор РАН, доктор экономических наук",
    location: "Россия, Москва",
    img: "bahtizin.jpg",
  },
  {
    name: "Попов Евгений Васильевич",
    descr: "член-корреспондент Уральского отделения РАН, профессор, доктор физико-математических наук, доктор экономических наук",
    location: "Россия, Екатеринбург",
    img: "popov.jpg",
  },
  {
    name: "Гринберг Руслан Семенович",
    descr: "член-корреспондент РАН, профессор, доктор экономических наук",
    location: "Россия, Москва",
    img: "grinberg.jpg",
  },
  {
    name: "Сезен Х. Кемал",
    descr:
      "PhD, профессор, профессор кафедры информационных систем управления, Школа прикладных наук, Университет Алтынбаш ",
    location: "Турция, Стамбул",
    img: "sezen.jpg",
  },
  {
    name: "Веселов Юрий Витальевич ",
    descr:
      "д.соц.н., профессор, заведующий кафедрой экономической социологии Санкт-Петербургского государственного университета ",
    location: "Россия, Санкт-Петербург",
    img: "veselov.jpg",
  },
  {
    name: "Старостин Александр Михайлович ",
    descr:
      "доктор политических наук, профессор ведущий научный сотрудник Ростовский государственный экономический университет (РГЭУ (РИНХ))",
    location: "Россия, Ростов-на-Дону",
    img: "starostin.jpg",
  },
  {
    name: "Шахбанова Мадина Магомедкамиловна",
    descr:
      "доктор социологических наук, ведущий научный сотрудник Отдела социологии, Институт истории, археологии и этнографии Дагестанского федерального исследовательского центра РАН",
    location: "Россия, г. Махачкала",
    img: "shahbanova.jpg",
  },
  {
    name: "Мадиярова Диана Макаевна",
    descr:
      "д.э.н., профессор кафедры Международных экономических отношений РУДН",
    location: "Россия г. Москва",
    img: "madiyarova.jpg",
  },
  {
    name: "Шапсугов Дамир Юсуфович",
    descr:
      "д.ю.н., профессор, профессор кафедры теории и истории права и государства Южно-Российского института управления – филиала РАНХиГС",
    location: "Россия, Ростов-на-Дону",
    img: "shapsugov.jpg",
  },
  {
    name: "Понеделков Александр Васильевич ",
    descr:
      "д.полит.н., профессор, заведующий кафедрой политологии и этнополитики ЮРИУ РАНХиГС, заведующий лабораторией ЮРИУ РАНХиГС ",
    location: "Россия, Ростов-на-Дону",
    img: "ponedelkov.jpg",
  },
  {
    name: "Вольчик Вячеслав Витальевич",
    descr: "доктор экономических наук профессор, заведующий кафедрой экономической теории Южного федерального университета ",
    location: "Россия. Ростов-на-Дону",
    img: "volchik.jpg",
  },
  {
    name: "Молчанов Александр Сергеевич ",
    descr:
      "к.п.н., зам. руководителя комитета по профобразованию и подготовке кадров общероссийской общественной организации «Деловая Россия» ",
    location: "Россия, Москва",
    img: "molchanov.jpg",
  },
  {
    name: "Ушаков Денис Сергеевич ",
    descr: "д.э.н., профессор, Университет Суан Сунандха Раджабат ",
    location: "Таиланд, г. Бангкок",
    img: "ushakov.jpg",
  },
  {
    name: "Черкасова Татьяна Павловна",
    descr:
      "Доктор экономических наук, профессор, декан факультета политологии Южно-Российского института управления – филиала РАНХиГС",
    location: "Россия, Ростов-на-Дону",
    img: "cherkasova.jpg",
  },
  {
    name: "Анопченко Татьяна Юрьевна ",
    descr:
      "доктор экономических наук, профессор кафедры государственного и муниципального управления Российского экономического университета имени Г.В. Плеханова",
    location: "Россия, г. Москва",
    img: "anopchenko.jpg",
  },
  {
    name: 'Каргинова-Губинова Валентина Владимировна',
    descr: 'кандидат экономических наук, старший научный сотрудник отдела региональной экономической политики Института экономики Карельского научного центра РАН',
    location: 'Россия. г. Петрозаводск',
    img: 'karginova.jpg'
  },
  {
    name: 'Шалов Тимур Борисович',
    descr: 'доктор сельскохозяйственных наук, профессор, начальник отдела науки и аспирантуры Абхазского государствененого университета',
    location: 'Республика Абхазия. г. Сухум',
    img: 'shalov.jpg'
  },
  {
    name: 'Халматжанова Гульчехра Джурабаевна ',
    descr: 'кандидат экономических наук, профессор Ферганского государственного университета',
    location: 'Республика Узбекистан, г. Фергана',
    img: 'halmatzhanova.jpg'
  },
  {
    name: 'Малов Владимир Юрьевич',
    descr: 'Доктор экономических наук, профессор Главный научный сотрудник Института экономики и организации промышленного производства СО РАН',
    location: 'Россия. г. Новосибирск',
    img: 'malov.jpg'
  },
];
